<template>
  <div>
    <v-card>
      <v-card-title>
        <h1 class="float-left">Basic Information</h1>
        <v-btn
          class="blue--text text--lighten-2 font-weight-bold float-right"
          small
          text
          @click="updateDetail()"
        >
          Save
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9" sm="6">
            <v-text-field
              v-model="currentLocation.name"
              label="Name"
              type="text"
              @keydown.enter.native="updateDetail()"
            />
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field
              v-model="currentLocation.contactName"
              label="Contact Name"
              type="text"
              @keydown.enter.native="updateDetail()"
            />
          </v-col>
          <v-col cols="12" md="9" sm="6">
            <detail-location-auto-complete
              :address="fullAddress"
              @event="setLocation"
            />
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field
              v-model="currentLocation.contactPhone"
              label="Contact Phone"
              type="text"
              @keydown.enter.native="updateDetail()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-row>
        <v-col
          :style="
            $vuetify.breakpoint.smAndUp ? 'border-right:1px solid #CCCCCC' : ''
          "
          cols="12"
          md="6"
        >
          <v-card-title>
            <h1 class="float-left">Operational Information</h1>
            <v-btn
              class="blue--text text--lighten-2 font-weight-bold float-right"
              small
              text
              @click="updateDetail()"
            >
              Save
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="currentLocation.earlyTime"
                  v-mask="'##:##'"
                  :rules="[rules.time]"
                  class="pt-0"
                  label="Early Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  @blur="updateDetail()"
                  @keydown.enter.native="updateDetail()"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="currentLocation.lateTime"
                  v-mask="'##:##'"
                  :rules="[rules.time]"
                  class="pt-0"
                  label="Late Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  @blur="updateDetail()"
                  @keydown.enter.native="updateDetail()"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="currentLocation.notes"
                  auto-grow
                  class="pt-0 pb-0"
                  label="Notes"
                  row-height="30"
                  rows="4"
                  @keydown.enter.native="updateDetail()"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6">
          <v-card-title>
            <h1 class="float-left">Attached Customers</h1>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="pb-0" cols="12" md="6">
                <v-row>
                  <v-col class="py-0" cols="10" md="9">
                    <customer-auto-complete />
                  </v-col>
                  <v-col class="py-0 pl-0" cols="2" md="3">
                    <v-btn
                      :class="
                        $vuetify.breakpoint.smAndDown ? 'float-right' : ''
                      "
                      class="
                        while--text
                        blue
                        text--lighten-2
                        font-weight-bold
                        float-left
                        ml-2
                        mt-2
                      "
                      small
                      @click="addToLocation()"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pb-0" cols="12" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="mt-n2"
                  hide-details
                  label="Search"
                  single-line
                />
              </v-col>
              <v-col class="px-1" cols="12">
                <div class="px-1 pb-1" style="max-height: 335px">
                  <v-data-table
                    :footer-props="{
                      'items-per-page-options': [5],
                    }"
                    :headers="headers"
                    :items="currentLocation.customerCompanies"
                    :items-per-page="5"
                    :search="search"
                    calculate-widths
                    class="elevation-1"
                    fixed-header
                  >
                    <template v-slot:item.id="{ item }">
                      <v-btn
                        class="while--text red text--lighten-2 font-weight-bold"
                        small
                        @click="removeFromLocation(item.id)"
                      >
                        Remove
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import DetailLocationAutoComplete from "@/views/pages/components/DetailLocationAutoComplete"

export default {
  name: "Details",
  components: {
    CustomerAutoComplete,
    DetailLocationAutoComplete,
  },
  props: {
    locationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      rules: validation.rules,
      earlyTimeMenu: false,
      lateTimeMenu: false,
      customers: [],
      search: null,
      headers: [
        {
          text: "Customer",
          align: "start",
          sortable: false,
          value: "name",
          width: "90%",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "id",
        },
      ],
      location: null,
      fullAddress: null,
    }
  },
  computed: {
    ...mapGetters("location", ["currentLocation"]),
    ...mapGetters("generic", ["customerId"]),
  },
  watch: {
    currentLocation() {
      this.customers = this.currentLocation.customerCompanies
      this.location = this.currentLocation
      this.fullAddress = {
        zipcode:
          this.currentLocation.postalCode || this.currentLocation.caPostalCode,
        address: this.currentLocation.address,
        city: this.currentLocation.city,
        state: this.currentLocation.stateProvinceRegion,
        country: this.currentLocation.country,
      }

      this.currentLocation.earlyTime = this.currentLocation.earlyTime.substr(
        0,
        5
      )
      this.currentLocation.lateTime = this.currentLocation.lateTime.substr(0, 5)
    },
  },
  mounted() {
    this.getLocationDetail()
  },
  methods: {
    setLocation(event) {
      const {
        city = "",
        stateCode = "",
        countryCode = "",
        street = "",
        houseNumber = "",
        postalCode = "",
      } = event.address

      this.location = {
        ...this.location,
        address: `${houseNumber ? houseNumber + " " : ""}${street}`,
        city: city,
        stateProvinceRegion: stateCode,
        postalCode: postalCode,
        country: countryCode,
      }
    },
    getLocationDetail() {
      const payload = {
        locationId: this.locationId,
      }
      this.$store.dispatch("location/GET_LOCATION", payload)
    },
    updateDetail() {
      const payload = {
        locationId: this.locationId,
        data: {
          address: this.location.address,
          city: this.location.city,
          state_province_region: this.location.stateProvinceRegion,
          postal_code: this.location.postalCode,
          country: this.location.country,
          name: this.currentLocation.name,
          geo_point: this.currentLocation.geoPoint,
          early_time: this.currentLocation.earlyTime,
          late_time: this.currentLocation.lateTime,
          contact_name: this.currentLocation.contactName,
          contact_phone: this.currentLocation.contactPhone,
          notes: this.currentLocation.notes,
        },
      }
      this.$store.dispatch("location/UPDATE_LOCATION", payload)
    },
    addToLocation() {
      if (this.customerId) {
        const payload = {
          id: this.currentLocation.id,
          customerCompany: this.customerId,
        }
        this.$store
          .dispatch("location/UPDATE_CUSTOMER_LOCATION", payload)
          .then((data) => {
            this.$store.commit("generic/setCustomerId", null)
            this.getLocationDetail()
          })
      }
    },
    removeFromLocation(customerId) {
      if (customerId) {
        const payload = {
          id: this.currentLocation.id,
          customerCompany: customerId,
        }
        this.$store
          .dispatch("location/DELETE_CUSTOMER_FROM_LOCATION", payload)
          .then((data) => {
            this.getLocationDetail()
          })
      }
    },
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
</style>
