<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="text-right pa-0">
        <v-btn
          v-if="!addDocument"
          text
          class="blue--text text--lighten-2 font-weight-bold"
          @click="showAddDocument()"
        >
          Add Document
        </v-btn>
      </v-col>
      <v-col v-if="!addDocument">
        <v-data-table
          id="tblDocument"
          :headers="headers"
          :items="documents"
          class="elevation-1"
          calculate-widths
          disable-pagination
          hide-default-footer
          hide-slider
          fixed-header
          :loading="loadingIcon"
        >
          <template v-slot:item.file="{ item }">
            <a :href="item.file" target="_blank" style="text-decoration: none">
              <v-icon class="pl-3 pr-1 gray--text text--lighten-2" medium>
                mdi-file-document
              </v-icon>
            </a>
          </template>
          <template v-slot:item.modifyDetail="{ item }">
            <p
              v-if="item.modifyDetail !== null"
              class="mb-0"
              style="width: 70%; float: left"
            >
              <span class="font-weight-bold">{{
                format_date(item.modifyDetail.updatedAt)
              }}</span>
              <br />
              <span class="subtitle">{{
                item.modifyDetail.uploadedByDisplay
              }}</span>
            </p>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" class="float-right" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn text small @click="editDocument(item)"> Edit </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    class="red--text"
                    text
                    small
                    @click="deleteDocument(item)"
                  >
                    Delete
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-else>
        <v-card>
          <v-container>
            <v-row>
              <v-text-field
                v-model="documentName"
                type="text"
                label="Name"
                class="col-md-4 pa-2 offset-md-4"
              />
            </v-row>
            <v-row>
              <v-file-input
                v-model="fileInput"
                show-size
                counter
                label="File input"
                accept="image/*,.pdf,.doc,.docx,.csv"
                class="col-md-4 offset-md-4"
              />
              <a v-if="hasEdit" :href="fileURL" target="_blank">View File</a>
            </v-row>
            <v-row>
              <v-progress-linear
                v-if="uploadProgress > 0"
                v-model="uploadProgress"
                height="25"
              >
                <strong>{{ Math.ceil(uploadProgress) }}%</strong>
              </v-progress-linear>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  text
                  class="blue white--text font-weight-bold mt-4 offset-md-4"
                  @click="uploadDocument()"
                >
                  Upload
                </v-btn>
                <v-btn
                  text
                  class="grey white--text font-weight-bold mt-4 ml-2"
                  @click="cancelUpload()"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-text> Please confirm document deletion </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="grey darken-1" text small @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text small @click="removeRecord()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import moment from "moment"
import common from "@/utils/common"
export default {
  name: "Documents",
  props: {
    locationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      hasEdit: false,
      index: -1,
      loadingIcon: false,
      addDocument: false,
      documentName: "",
      fileURL: "",
      fileInput: null,
      documentId: 0,
      documentType: null,
      documentTypes: [
        {
          id: 1,
          name: "General",
        },
        {
          id: 2,
          name: "Insurance",
        },
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "Document", value: "file" },
        { text: "Modified", value: "modifyDetail" },
      ],
      documents: [
        {
          id: 0,
          createdAt: "10/01/2020",
          modifyDetail: {
            updatedAt: "10/01/2020",
            uploadedByDisplay: "Kris Tryber",
          },
          file: null,
          fileName: null,
          name: "Demo File",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("location", {
      locationDocuments: "locationDocuments",
      locationDocument: "locationDocument",
      uploadProgress: "uploadProgress",
    }),
  },
  watch: {
    locationDocuments() {
      this.documents = this.locationDocuments.results
    },
    locationDocument() {
      this.documentName = this.locationDocument.name
      this.fileURL = this.locationDocument.file
    },
  },
  mounted() {
    this.loadDocumentData()
  },
  methods: {
    loadDocumentData() {
      const documentPayload = `${process.env.VUE_APP_BASE_URL}/locations/api/document/${this.locationId}/?limit=100&offset=0`
      this.$store.dispatch("location/GET_LOCATION_DOCUMENTS", documentPayload)
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM-DD-YYYY")
      }
    },
    showAddDocument() {
      this.addDocument = true
    },
    uploadDocument() {
      if (this.documentId === 0) {
        if (this.documentName === "" || this.fileInput === null) {
          const snackbar = {
            status: true,
            text: "All fields are required",
            type: common.data.snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", snackbar)
        } else {
          const flData = new FormData()
          flData.append("file", this.fileInput)
          flData.append("name", this.documentName)
          const documentPayload = {
            locationURL: `${process.env.VUE_APP_BASE_URL}/locations/api/document/${this.locationId}/?limit=100&offset=0`,
            fmData: flData,
            id: this.locationId,
          }
          this.$store.dispatch(
            "location/ADD_LOCATION_DOCUMENT",
            documentPayload
          )
          this.addDocument = false
          this.hasEdit = false
        }
      } else {
        if (this.documentName === "") {
          const snackbar = {
            status: true,
            text: "All fields are required",
            type: common.data.snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", snackbar)
        } else {
          const flData = new FormData()
          if (this.fileInput !== null) {
            flData.append("file", this.fileInput)
          }
          flData.append("name", this.documentName)
          const documentPayload = {
            locationId: this.locationId,
            id: this.documentId,
            fmData: flData,
          }
          this.$store.dispatch(
            "location/UPDATE_LOCATION_DOCUMENT",
            documentPayload
          )
          this.documentId = 0
          this.addDocument = false
          this.hasEdit = false
        }
      }
    },
    cancelUpload() {
      this.addDocument = false
      this.index = -1
      this.documentId = 0
      this.hasEdit = false
      this.documentType = null
      this.fileInput = null
      this.documentName = ""
    },
    removeRecord() {
      if (this.index > -1) {
        const item = this.documents[this.index]
        const payload = {
          id: item.id,
          locationURL: `${process.env.VUE_APP_BASE_URL}/locations/api/document/${this.locationId}/?limit=100&offset=0`,
        }
        this.$store.dispatch("location/DELETE_LOCATION_DOCUMENT", payload)
        this.dialog = false
      }
    },
    deleteDocument(item) {
      this.index = this.documents.indexOf(item)
      this.dialog = true
    },
    editDocument(item) {
      this.documentId = item.id
      this.$store.dispatch("location/GET_LOCATION_DOCUMENT_DETAIL", item.id)
      this.addDocument = true
      this.hasEdit = true
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
</style>
