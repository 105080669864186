<template>
  <div>
    <v-container fluid style="max-width: 1600px" class="pb-0 pt-0">
      <v-card>
        <div class="header-banner blue lighten-1">
          <v-row>
            <div class="col-md-5 d-flex align-center">
              <div
                class="
                  white
                  rounded-circle
                  d-inline-block
                  text-center
                  short-name
                  ml-md-10 ml-5
                  shortname-circal
                "
              >
                <div
                  class="
                    transition-swing
                    font-weight-bold
                    grey--text
                    font-size-42
                  "
                >
                  {{ shortLocationName }}
                </div>
              </div>
              <div class="white--text d-inline-block align-middle ml-4">
                <v-list-item-title class="mb-1 font-size-24">
                  {{ currentLocation.name }}
                </v-list-item-title>
                <div class="font-size-18">
                  {{ currentLocation.address }}
                </div>
                <div class="font-size-18">
                  {{ currentLocation.city }},
                  {{ currentLocation.stateProvinceRegion }}
                  {{ currentLocation.postalCode }}
                </div>
              </div>
            </div>
            <div class="col-md-7 pt-0 pb-0">
              <GmapMap
                id="map"
                ref="mapRef"
                :center="{
                  lat: Number(currentLocation.latitude),
                  lng: Number(currentLocation.longitude),
                }"
                :zoom="9"
                map-type-id="terrain"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'height: 220px; border-radius: 0px 4px 4px 0px'
                    : 'height: 220px; border-radius: 0px 0px 4px 0px'
                "
              >
                <GmapMarker
                  :position="{
                    lat: Number(currentLocation.latitude),
                    lng: Number(currentLocation.longitude),
                  }"
                />
              </GmapMap>
            </div>
          </v-row>
        </div>
        <div>
          <v-tabs v-model="currentTab" centered>
            <v-tab href="#tab-1"> Details </v-tab>
            <v-tab href="#tab-2"> Shipments </v-tab>
            <v-tab href="#tab-3"> Documents </v-tab>
            <v-tab href="#tab-4"> Notes </v-tab>
          </v-tabs>
        </div>
        <v-progress-linear :active="loadingIcon" color="amber" indeterminate />
      </v-card>
    </v-container>
    <v-container fluid style="max-width: 1600px" class="pt-0">
      <v-tabs-items v-model="currentTab" style="background: none">
        <v-tab-item value="tab-1">
          <detail-tab :location-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <shipment ref="shipmentTab" :location-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-3">
          <documents :location-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-4">
          <notes :location-id="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import DetailTab from "@/views/pages/location-components/Details"
import Shipment from "@/views/pages/location-components/Shipment"
import Documents from "@/views/pages/location-components/Documents"
import Notes from "@/views/pages/location-components/Notes"
import common from "@/utils/common"
export default {
  name: "LocationDetail",
  components: {
    DetailTab,
    Shipment,
    Documents,
    Notes,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      currentTab: "tab-1",
      loadStatusItems: [],
    }
  },
  computed: {
    ...mapGetters("location", {
      currentLocation: "currentLocation",
    }),
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
    }),
    shortLocationName() {
      let shortName = ""
      if (this.currentLocation.name !== undefined) {
        common.methods.setPageTitle(this.currentLocation.name)
        shortName = this.currentLocation.name.match(/\b(\w)/g).join("")
        shortName = shortName.charAt(0) + shortName.charAt(1)
      }
      return shortName
    },
  },
  mounted() {
    this.GetLocationDetail()
  },
  methods: {
    GetLocationDetail() {
      const payload = {
        locationId: this.id,
      }
      this.$store.dispatch("location/GET_LOCATION", payload)
    },
    changeTab(value) {
      if (value === "tab-2") {
        if (this.$refs.shipmentTab !== undefined) {
          this.$refs.shipmentTab.getShipments()
        }
      }
    },
  },
}
</script>
<style scoped>
.shortname-circal {
  width: 74px;
  height: 74px;
  line-height: 74px;
  margin-bottom: 20px;
}
.font-size-42 {
  font-size: 42px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
</style>
